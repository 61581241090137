import axios from 'axios';

const api = axios.create({
  baseURL: 'http://127.0.0.1:5000',
});

export const handlePostSubmission = async (
  assignmentId,
  userId,
  executableFile
) => {
  const formData = new FormData();
  formData.append('assignment_id', assignmentId);
  formData.append('user_id', userId);
  formData.append('executable', executableFile);

  const response = await api.post('/submissions', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};

import React from 'react';
import './Buttons.css';

const CreateButton = ({ onClick }) => {
  return (
    <div className="create-new-entry">
      <button onClick={onClick} className="create-new-entry-button">
        +
      </button>
    </div>
  );
};

const SmallCreateButton = ({ onClick }) => {
  return (
    <div className="small-create-new-entry">
      <button className="small-create-new-entry-button" onClick={onClick}>
        +
      </button>
    </div>
  );
};

const MainButton = ({ text, onClick, Icon }) => {
  return (
    <button id="main-btn" onClick={onClick} className="main-btn">
      <div className="button-content-container">
        {Icon && <Icon />}
        <span>{text}</span>
      </div>
    </button>
  );
};

const DeleteButton = ({ onClick }) => {
  return (
    <button className="modal-btn delete-btn" onClick={onClick}>
      Excluir
    </button>
  );
};

const AddButton = ({ onClick }) => {
  return (
    <button className="modal-btn add-btn" onClick={onClick}>
      Adicionar
    </button>
  );
};

const EditButton = ({ onClick }) => {
  return (
    <button className="modal-btn add-btn" onClick={onClick}>
      Editar
    </button>
  );
};

const CancelButton = ({ onClick }) => {
  return (
    <button className="modal-btn cancel-btn" onClick={onClick}>
      Cancelar
    </button>
  );
};

export {
  CreateButton,
  SmallCreateButton,
  MainButton,
  DeleteButton,
  AddButton,
  CancelButton,
  EditButton,
};

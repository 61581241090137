import React, { useState } from 'react';
import './Dialogs.css';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import {
  CancelButton,
  DeleteButton,
  AddButton,
  EditButton,
} from '../Buttons/Buttons';

const DeleteDialog = React.forwardRef(({ closeDialog, confirmDelete }, ref) => {
  return (
    <dialog ref={ref} className="custom-dialog">
      <div className="dialog-content">
        <div className="icon-container">
          <IoIosCloseCircleOutline className="close-icon" />
        </div>
        <h2>Confirmação de exclusão</h2>
        <p>Tem certeza que deseja excluir este usuário?</p>
        <div className="dialog-buttons">
          <DeleteButton onClick={confirmDelete} />
          <CancelButton onClick={closeDialog} />
        </div>
      </div>
    </dialog>
  );
});

const AddDialog = React.forwardRef(({ closeDialog, add, newUser, setNewUser }, ref) => {
  return (
    <dialog ref={ref} className="add-dialog">
      <h2>Adicionar um novo usuário</h2>
      <input
        type="text"
        placeholder="Login / ID do usuário"
        value={newUser}
        onChange={(e) => setNewUser(e.target.value)}
      />
      <div className="dialog-actions">
        <button className="add-button" onClick={add}>
          Adicionar
        </button>
        <button className="cancel-button" onClick={closeDialog}>
          Cancelar
        </button>
      </div>
    </dialog>
  );
});

const CreateClassDialog = React.forwardRef(({ closeDialog, add }, ref) => {
  // Estados para armazenar os valores do input de nome e os arquivos CSV
  const [className, setClassName] = useState('');
  const [studentCsvFile, setStudentCsvFile] = useState(null);
  const [monitorCsvFile, setMonitorCsvFile] = useState(null);
  const [teacherCsvFile, setTeacherCsvFile] = useState(null);

  // Função para lidar com a submissão
  const handleAdd = () => {
    if (className && studentCsvFile && monitorCsvFile && teacherCsvFile) {
      add(className, studentCsvFile, monitorCsvFile, teacherCsvFile);
    } else {
      console.error('Preencha todos os campos e faça o upload dos arquivos.');
    }
  };

  return (
    <dialog
      ref={ref}
      className="custom-dialog"
      style={{ width: 'fit-content' }}
    >
      <div className="dialog-content">
        <h2>Cadastrar uma nova turma</h2>
        <div className="user-input-form">
          <div className="csv-input">
            <label>Nome da turma:</label>
            <input
              type="text"
              className="create-class-input"
              value={className}
              onChange={(e) => setClassName(e.target.value)}
            />
          </div>
          <div className="csvs">
            <div className="csv-input">
              <label>Upload CSV de alunos:</label>
              <input
                type="file"
                className="login-input"
                onChange={(e) => setStudentCsvFile(e.target.files[0])}
              />
            </div>
            <div className="csv-input">
              <label>Upload CSV de monitores:</label>
              <input
                type="file"
                className="login-input"
                onChange={(e) => setMonitorCsvFile(e.target.files[0])}
              />
            </div>
            <div className="csv-input">
              <label>Upload CSV de professores:</label>
              <input
                type="file"
                className="login-input"
                onChange={(e) => setTeacherCsvFile(e.target.files[0])}
              />
            </div>
          </div>
        </div>
        <div className="dialog-buttons">
          <AddButton onClick={handleAdd} />
          <CancelButton onClick={closeDialog} />
        </div>
      </div>
    </dialog>
  );
});

const EditClassDialog = React.forwardRef(
  ({ closeDialog, edit, selectedClasse }, ref) => {
    const [className, setClassName] = useState(selectedClasse || '');

    const handleAdd = () => {
      if (className) {
        edit(className);
      } else {
        console.error('Preencha todos os campos');
      }
    };

    return (
      <dialog
        ref={ref}
        className="custom-dialog"
        style={{ width: 'fit-content' }}
      >
        <div className="dialog-content">
          <h2>Edi├º├úo de turma</h2>
          <div className="user-input-form">
            <div className="csv-input">
              <label>Nome da turma:</label>
              <input
                type="text"
                className="create-class-input"
                value={className}
                onChange={(e) => setClassName(e.target.value)}
              />
            </div>
          </div>
          <div className="dialog-buttons" style={{ marginTop: '12px' }}>
            <EditButton onClick={handleAdd} />
            <CancelButton onClick={closeDialog} />
          </div>
        </div>
      </dialog>
    );
  }
);

export { DeleteDialog, AddDialog, CreateClassDialog, EditClassDialog };

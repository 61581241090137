import React from 'react';

const Table = () => {
  return (
    <table class="styled-table">
      <thead>
        <tr>
          <th>Aluno</th>
          <th>Submissões</th>
          <th>Testes aprovados</th>
        </tr>
      </thead>
      <tbody id="table-body">
        <tr>
          <td>Gabriel</td>
          <td>3</td>
          <td>0/10</td>
        </tr>

        <tr>
          <td>André</td>
          <td>3</td>
          <td>8/10</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Table;

import axios from 'axios';
import { Base64 } from 'js-base64';

const baseURL = 'http://127.0.0.1:5000/assignments';

const api = axios.create({
  baseURL:baseURL
});

export const handleDeleteAssignment = async (assignmentId) => {
  await api.delete(`/${assignmentId}`);
};

export const handleGetAssignmentTests = async () => {
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const selectedAssignment = JSON.parse(localStorage.getItem('selectedAssignment'));
  const credentials = Base64.encode(
    `${storedUser.username}:${storedUser.role}`
  );
  const { data } = await api.get(`/${selectedAssignment.id}/tests`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });

  return data;
};

export const handleGetAssigmentDescription = async () => {
  try {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const selectedAssignment = JSON.parse(localStorage.getItem('selectedAssignment'));
    const credentials = Base64.encode(
      `${storedUser.username}:${storedUser.role}`
    );

    const response = await api.get(`/${selectedAssignment.id}/description`, {
      responseType: 'blob',
      headers: {
        Authorization: `Basic ${credentials}`,
      }
    });

    // Get the MIME type from the server response headers
    const mimeType = response.headers['content-type'];
    const fileBlob = new Blob([response.data], { type: mimeType });
    const fileUrl = URL.createObjectURL(fileBlob);
    
    const contentDisposition = response.headers['content-disposition']?.split("filename=")
    const filename = contentDisposition?.length > 1 ? contentDisposition[1].trim() : undefined;

    // Create a temporary anchor element to trigger download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = filename; // Set the filename here
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    setTimeout(() => URL.revokeObjectURL(fileUrl), 1000);
  } catch (error) {
    console.error('Erro ao recuperar arquivo:', error);
  }
}

export const handleGetAssigmentAuxFile = async () => {
  try {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const selectedAssignment = JSON.parse(localStorage.getItem('selectedAssignment'));
    const credentials = Base64.encode(
      `${storedUser.username}:${storedUser.role}`
    );

    const response = await api.get(`/${selectedAssignment.id}/aux`, {
      responseType: 'blob',
      headers: {
        Authorization: `Basic ${credentials}`,
      }
    });

    if (response.status < 200 || response.status > 299){
      alert("Não há arquivos auxiliares para essa tarefa.");
      return;
    }

    const mimeType = response.headers['content-type'];
    const fileBlob = new Blob([response.data], { type: mimeType });
    const fileUrl = URL.createObjectURL(fileBlob);
    
    const contentDisposition = response.headers['content-disposition']?.split("filename=")
    const filename = contentDisposition?.length > 1 ? contentDisposition[1].trim() : undefined;

    // Create a temporary anchor element to trigger download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = filename; // Set the filename here
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    setTimeout(() => URL.revokeObjectURL(fileUrl), 1000);
  } catch (error) {
    console.error('Erro ao recuperar arquivo:', error);
  }
}

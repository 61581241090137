import React, { useRef, useState } from 'react';
import axios from 'axios';
import { MdDelete } from 'react-icons/md';
import { SmallCreateButton } from '../Buttons/Buttons';
import { AddDialog, DeleteDialog } from '../Dialogs/Dialogs';

const api = axios.create({
  baseURL: 'http://127.0.0.1:5000/classes',
});

const UserList = ({ title, users, role, refreshUsers }) => {
  const deleteDialogRef = useRef(null);
  const addDialogRef = useRef(null);
  const [userList, setUserList] = useState(users);
  const [selectedUserId, setSelectedUser] = useState('');
  const [newUserId, setNewUserId] = useState(''); // For new user input

  const openDeleteDialog = (user_id) => {
    setSelectedUser(user_id);
    deleteDialogRef.current.showModal();
  };

  const closeDeleteDialog = () => {
    deleteDialogRef.current.close();
  };

  const openAddDialog = () => {
    setNewUserId(''); // Reset new user input
    addDialogRef.current.showModal();
  };

  const closeAddDialog = () => {
    addDialogRef.current.close();
  };

  const confirmDelete = () => {
    const curr_class = JSON.parse(localStorage.getItem('selectedClass'));
    api.patch(`/${curr_class.id}/userdel`, { user_id: selectedUserId })
      .then(() => {
        refreshUsers(); // Refresh the user list
        closeDeleteDialog();
      })
      .catch((error) => {
        console.error('Error deleting user:', error);
      });
  };

  const addUser = () => {
    if (!newUserId) {
      alert('Please enter a user ID or name before adding.');
      return;
    }

    const curr_class = JSON.parse(localStorage.getItem('selectedClass'));
    const newUserDetails = { user_id: newUserId, role };

    api.patch(`/${curr_class.id}/useradd`, newUserDetails)
      .then(() => {
        refreshUsers(); // Refresh the user list
        closeAddDialog();
      })
      .catch((error) => {
        console.error('Error adding user:', error);
      });
  };

  return (
    <>
      <div className="list-header">
        <h2 className="list-title">
          {title} ({userList.length})
        </h2>
        <SmallCreateButton onClick={openAddDialog} />
      </div>

      <div className="users">
        {userList.map((user, index) => (
          <div className="user" key={index}>
            <label>{user.user_id}</label>
            <MdDelete
              onClick={() => openDeleteDialog(user.user_id)}
              className="delete-icon"
            />
          </div>
        ))}
      </div>

      <DeleteDialog
        ref={deleteDialogRef}
        closeDialog={closeDeleteDialog}
        confirmDelete={confirmDelete}
      />

      <AddDialog
        ref={addDialogRef}
        closeDialog={closeAddDialog}
        add={addUser}
        newUser={newUserId}
        setNewUser={setNewUserId}
      />
    </>
  );
};

export default UserList;
import React, { useState } from 'react';
import './Submission.css';
import SideBarMenu from '../Sidebar/Sidebar';
import SubmitionForm from './components/SubmitionForm';
import Table from './components/SubmissionTable';
import { TestPanel, TestInformation } from './components/Tests';

const Submission = () => {
  const studentClass =  JSON.parse(localStorage.getItem('selectedClass'));
  const assignment = JSON.parse(localStorage.getItem('selectedAssignment'));
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const isTeacher = storedUser.role === 'TEACHER';
  const [showTestInformation, setShowTestInformation] = useState(false);

  const [testStatus, setTestStatus] = useState(Array(10).fill('start'));

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <SideBarMenu />
      {isTeacher ? (
        <Tabs
          assignment={assignment}
          studentClass={studentClass}
          testStatus={testStatus}
          setTestStatus={setTestStatus}
          showTestInformation={showTestInformation}
          setShowTestInformation={setShowTestInformation}
        />
      ) : (
        <SubmissionPage
          assignment={assignment}
          studentClass={studentClass}
          testStatus={testStatus}
          setTestStatus={setTestStatus}
          showTestInformation={showTestInformation}
          setShowTestInformation={setShowTestInformation}
        />
      )}
    </div>
  );
};

const SubmissionPage = ({
  assignment,
  studentClass,
  testStatus,
  setTestStatus,
  showTestInformation,
  setShowTestInformation,
}) => {
  return (
    <div className="submission-page">
      <SubmitionForm
        assignment={assignment}
        studentClass={studentClass}
        testStatus={testStatus}
        setTestStatus={setTestStatus}
      />
      {showTestInformation ? (
        <TestInformation
          showTestInformation={showTestInformation}
          setShowTestInformation={setShowTestInformation}
        />
      ) : (
        <TestPanel
          showTestInformation={showTestInformation}
          setShowTestInformation={setShowTestInformation}
        />
      )}
    </div>
  );
};

const Tabs = ({
  assignment,
  studentClass,
  testStatus,
  setTestStatus,
  showTestInformation,
  setShowTestInformation,
}) => {
  const [activeTab, setActiveTab] = useState('tarefas');
  const renderContent = () => {
    if (activeTab === 'tarefas') {
      return (
        <SubmissionPage
          assignment={assignment}
          studentClass={studentClass}
          testStatus={testStatus}
          setTestStatus={setTestStatus}
          showTestInformation={showTestInformation}
          setShowTestInformation={setShowTestInformation}
        />
      );
    }

    return (
      <div className="submission-page">
        <div className="submission-info">
          <Table />
        </div>

        {showTestInformation ? (
          <TestInformation
            showTestInformation={showTestInformation}
            setShowTestInformation={setShowTestInformation}
          />
        ) : (
          <TestPanel
            showTestInformation={showTestInformation}
            setShowTestInformation={setShowTestInformation}
          />
        )}
      </div>
    );
  };

  return (
    <div className="tab-container">
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === 'tarefas' ? 'active' : ''}`}
          onClick={() => setActiveTab('tarefas')}
        >
          Tarefas
        </button>
        <button
          className={`tab-button ${activeTab === 'pessoas' ? 'active' : ''}`}
          onClick={() => setActiveTab('pessoas')}
        >
          Alunos
        </button>
      </div>

      <div className="tab-content">{renderContent()}</div>
    </div>
  );
};

export default Submission;

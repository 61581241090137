import axios from 'axios';
import { Base64 } from 'js-base64';

const api = axios.create({
  baseURL: 'http://127.0.0.1:5000/classes',
});

export const handleGetUserClasses = async (userId) => {
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const credentials = Base64.encode(
    `${storedUser.username}:${storedUser.role}`
  );

  const { data } = await api.get(`/`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });

  return data;
};

export const handleDeleteClass = async (classId) => {
  await api.delete(`/${classId}`);
};

export const handleGetClassAssignments = async () => {
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const selectedClass = JSON.parse(localStorage.getItem('selectedClass'));
  const credentials = Base64.encode(
    `${storedUser.username}:${storedUser.role}`
  );
  const { data } = await api.get(`/${selectedClass.id}/assignments`, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });

  return data;
};

export const handleCreateClass = async (
  name,
  studentFile,
  monitorFile,
  teacherFile
) => {
  const formData = new FormData();

  formData.append('name', name);
  formData.append('student', studentFile);
  formData.append('monitor', monitorFile);
  formData.append('teacher', teacherFile);

  try {
    await api.post(`/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log('Classe criada com sucesso!');
  } catch (error) {
    console.error('Erro ao criar a classe:', error);
  }
};

export const handleEditClass = async (name) => {
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const credentials = Base64.encode(
    `${storedUser.username}:${storedUser.role}`
  );
  const editClass = JSON.parse(localStorage.getItem('editClass'));

  try {
    await api.put(
      `/${editClass.id}`,
      { name },
      {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    );

    console.log('Classe editada com sucesso!');
  } catch (error) {
    console.error('Erro ao criar a classe:', error);
  }
};

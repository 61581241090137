import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import SideBarMenu from '../Sidebar/Sidebar';
import Cards from '../Cards/Cards';
import {
  handleGetUserClasses,
  handleDeleteClass,
  handleCreateClass,
  handleEditClass,
} from '../services/classes';
import LongMenu from '../Cards/Menu';
import { CreateClassDialog, EditClassDialog } from '../Dialogs/Dialogs';

const UserClasses = () => {
  const [studentClasses, setData] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const isTeacher = storedUser.role === 'TEACHER';

  const fetchData = async () => {
    try {
      const data = await handleGetUserClasses(storedUser.username);
      setData(data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [storedUser.username]);

  useEffect(() => {
    if (refreshTrigger) {
      fetchData();
      setRefreshTrigger(false);
    }
  }, [refreshTrigger]);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <SideBarMenu />
      <div className="main-content">
        <Classes 
          studentClasses={studentClasses} 
          isTeacher={isTeacher} 
          triggerRefresh={() => setRefreshTrigger(true)}
        />
      </div>
    </div>
  );
};

const Classes = ({ studentClasses, isTeacher }) => {
  const selectedClass = JSON.parse(localStorage.getItem('editClass'));
  const navigate = useNavigate();
  const createDialogRef = useRef(null);
  const editDialogRef = useRef(null);
  const [selectedEditClasse, setClasse] = useState(selectedClass?.name || '');

  const handleClick = (studentClass) => {
    localStorage.setItem('selectedClass', JSON.stringify(studentClass));
    navigate('/assignments');
  };

  const openAddDialog = () => {
    createDialogRef.current.showModal();
  };

  const closeAddDialog = () => {
    createDialogRef.current.close();
  };

  const createNewClass = async (
    name,
    studentFile,
    teacherFile,
    monitorFile
  ) => {
    await handleCreateClass(name, studentFile, teacherFile, monitorFile);

    closeAddDialog();
  };

  const openEditDialog = (classe) => {
    localStorage.setItem('editClass', JSON.stringify(classe));
    setClasse(classe.name);

    editDialogRef.current.showModal();
  };

  const closeEditDialog = () => {
    editDialogRef.current.close();
  };

  const editClass = async (name, studentFile, teacherFile, monitorFile) => {
    await handleEditClass(name, studentFile, teacherFile, monitorFile);

    closeEditDialog();
  };

  return (
    <>
      <Cards
        cardElements={studentClasses}
        isTeacher={isTeacher}
        accessAction={handleClick}
        Header={(pros) => getHeader({ ...pros, editAction: openEditDialog })}
        createAction={openAddDialog}
      />
      <CreateClassDialog
        ref={createDialogRef}
        closeDialog={closeAddDialog}
        add={createNewClass}
      />
      <EditClassDialog
        selectedClasse={selectedEditClasse}
        ref={editDialogRef}
        closeDialog={closeEditDialog}
        edit={editClass}
      />
      ;
    </>
  );
};

const getHeader = ({ element: classes, isTeacher, editAction }) => {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <h2>{classes.name}</h2>
        {isTeacher && (
          <LongMenu
            onDelete={() => handleDeleteClass(classes.id)}
            onEdit={() => editAction(classes)}
          />
        )}
      </div>
    </>
  );
};

export default UserClasses;

import React, { useState } from 'react';
import { MainButton } from '../../Buttons/Buttons';
import { CiClock1 } from 'react-icons/ci';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { IoMdDownload } from 'react-icons/io';
import { handlePostSubmission } from '../../services/submission';
import { handleGetAssigmentAuxFile, handleGetAssigmentDescription } from '../../services/assignments';

const SubmitionForm = ({
  assignment,
  studentClass,
  testStatus,
  setTestStatus,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submiteFile, setSubmiteFile] = useState(null);
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const { DateTime } = require('luxon');
  const gmtDate = DateTime.fromHTTP(assignment.due_date, {
    zone: 'utc',
  });
  const localDate = gmtDate.setZone('America/Sao_Paulo');
  const timeZoneDate = localDate.plus({ hours: 3 });
  const formattedDate = timeZoneDate.toFormat('dd/MM/yyyy');

  const handleSubmit = async () => {
    setIsSubmitting(true);

    await handlePostSubmission(
      assignment.id,
      storedUser.username,
      submiteFile
    );
    const randomTime = Math.floor(Math.random() * (5000 - 2000 + 1)) + 2000;

    setTestStatus(testStatus.map(() => 'processing'));

    setTimeout(() => {
      setTestStatus(
        testStatus.map(() => (Math.random() > 0.5 ? 'success' : 'fail'))
      );
      setIsSubmitting(false);
    }, randomTime);
  };

  return (
    <div className="submission-info">
      <div className="submission-header">
        <h2>Olá, {storedUser.name}</h2>
        <label>{studentClass.name}</label>
        <label>{assignment.title}</label>
        <div className="assignment-time">
          <CiClock1 />
          <label>{formattedDate} 23:59</label>
        </div>
        <div className="buttons">
          <MainButton
            Icon={IoDocumentTextOutline}
            text={'Enunciado'}
            onClick={handleGetAssigmentDescription}
          />
          <MainButton
            Icon={IoMdDownload}
            text={'Arquivos auxiliares'}
            onClick={handleGetAssigmentAuxFile}
          />
        </div>
      </div>
      <div className="submission-form">
        <label>Arquivo</label>
        <input
          type="file"
          id="file"
          className="login-input"
          name="file"
          onChange={(e) => setSubmiteFile(e.target.files[0])}
        />
        <MainButton
          text="Submeter"
          onClick={handleSubmit}
          disabled={isSubmitting}
        />
      </div>
    </div>
  );
};

export default SubmitionForm;

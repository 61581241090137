import React, { useState, useEffect } from 'react';
import './Assignments.css';
import { CiClock1 } from 'react-icons/ci';
import { useNavigate, useLocation } from 'react-router-dom';
import SideBarMenu from '../Sidebar/Sidebar';
import UserList from '../UserList/UserList';
import Cards from '../Cards/Cards';
import LongMenu from '../Cards/Menu';
import { handleDeleteAssignment } from '../services/assignments';
import { handleGetClassAssignments } from '../services/classes';
import axios from 'axios';

const api = axios.create({
  baseURL: 'http://127.0.0.1:5000/classes',
});

const UserAssignments = () => {
  const location = useLocation();
  const [assignments, setData] = useState([]);
  // const { studentClass } = location.state || {};
  const studentClass = JSON.parse(localStorage.getItem('selectedClass'));

  const navigate = useNavigate();
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const isTeacher = storedUser.role === 'TEACHER';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await handleGetClassAssignments();

        console.log(
          JSON.stringify(`O useEffect do assignments: ${JSON.stringify(data)}`)
        );

        setData(data);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };
    console.log('Veio no fetch');

    fetchData();
  }, []);

  const handleClick = (assignment) => {
    localStorage.setItem('selectedAssignment', JSON.stringify(assignment));
    navigate('/submissions');
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <SideBarMenu />
      <div className="main-content">
        {isTeacher ? (
          <Tabs
            classe={studentClass}
            assignments={assignments}
            isTeacher={isTeacher}
          />
        ) : (
          <Cards
            cardElements={assignments}
            isTeacher={isTeacher}
            accessAction={handleClick}
            Header={getHeader}
            Content={getCardContent}
          />
        )}
      </div>
    </div>
  );
};

const Tabs = ({ classe, isTeacher, assignments }) => {
  const [selectedClass, setSelectedClass] = useState(
    JSON.parse(localStorage.getItem('selectedClass'))
  );
  const [activeTab, setActiveTab] = useState('tarefas');
  const [userMap, setUserMap] = useState({}); // Store users by role
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();

  // Fetch users by role and update the map
  const fetchUsersByRole = async () => {
    const roles = ['TEACHER', 'MONITOR', 'STUDENT'];
    const newUserMap = {};

    try {
      for (const role of roles) {
        const response = await api.get(`/${selectedClass.id}/users?role=${role}`);
        newUserMap[role] = response.data;
      }
      setUserMap(newUserMap);
    } catch (error) {
      console.error('Error fetching class users:', error);
    }
  };

  // Triggered by UserList after add/delete
  const refreshUsers = () => {
    setRefresh((prev) => !prev);
    fetchUsersByRole(); // Fetch updated user data by role
  };

  useEffect(() => {
    fetchUsersByRole(); // Fetch users by role on component mount
  }, [selectedClass.id, refresh]);

  const handleClick = (assignment) => {
    localStorage.setItem('selectedAssignment', JSON.stringify(assignment));
    navigate('/submissions');
  };

  const handleCreateClick = () => {
    navigate('/assignments/create', { state: { classe } });
  };

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    if (activeTab === 'tarefas') {
      return (
        <Cards
          cardElements={assignments}
          isTeacher={isTeacher}
          accessAction={handleClick}
          Header={getHeader}
          Content={getCardContent}
          createAction={handleCreateClick}
        />
      );
    }

    return (
      <>
        <UserList
          title="Professores"
          users={userMap['TEACHER'] || []}
          role="TEACHER"
          refreshUsers={refreshUsers}
        />
        <UserList
          title="Monitores"
          users={userMap['MONITOR'] || []}
          role="MONITOR"
          refreshUsers={refreshUsers}
        />
        <UserList
          title="Alunos"
          users={userMap['STUDENT'] || []}
          role="STUDENT"
          refreshUsers={refreshUsers}
        />
      </>
    );
  };

  return (
    <div>
      {/* Tab Navigation UI */}
      <div className="tab-navigation">
        <button
          className={`tab-button ${activeTab === 'tarefas' ? 'active' : ''}`}   
          onClick={() => changeTab('tarefas')}
        >
          Tarefas
        </button>
        <button
          className={`tab-button ${activeTab === 'usuarios' ? 'active' : ''}`}   
          onClick={() => changeTab('usuarios')}
        >
          Usuários
        </button>
      </div>

      {/* Content Rendering */}
      <div className="tab-content">
        {renderContent()}
      </div>
    </div>
  );
};

const getHeader = ({ element: assignment, isTeacher }) => {
  const { DateTime } = require('luxon');
  const gmtDate = DateTime.fromHTTP(assignment.due_date, { zone: 'utc' });
  const localDate = gmtDate.setZone('America/Sao_Paulo');
  const timeZoneDate = localDate.plus({ hours: 3 });
  const formattedDate = timeZoneDate.toFormat('dd/MM/yyyy');

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <h2>{assignment.title}</h2>
        {isTeacher && (
          <LongMenu onDelete={() => handleDeleteAssignment(assignment.id)} />
        )}
      </div>
      <div className="assignment-time">
        <CiClock1 />
        <span>{formattedDate} 23:59</span>
      </div>
    </>
  );
};

const getCardContent = ({ isTeacher, element: assignment }) => {
  if (isTeacher) {
    return (
      <>
        <label>Status: {assignment.status}</label>
        <label>
          {assignment.delivered} / {assignment.total_assignees} entregas
        </label>
      </>
    );
  }

  return (
    <>
      <label>{assignment.total_submissions} submissões feitas</label>
      <label>
        {assignment.total_success_cases} / {assignment.total_tests} testes
        aprovados
      </label>
    </>
  );
};

export default UserAssignments;

import React, { useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { CiLock } from 'react-icons/ci';
import { handleGetAssignmentTests } from '../../services/assignments';

const TestPanel = ({
  showTestInformation,
  setShowTestInformation,
}) => {

  const status = "mock-status";
  const [openTests, setOpenTests] = useState([]);
  const [closedTests, setClosedTests] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await handleGetAssignmentTests();
        
        setOpenTests(data.filter((test) => test.is_open));
        setClosedTests(data.filter((test) => !test.is_open));
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchData();
  }, [])

  const renderTestStatus = (status) => {
    if (status === 'processing') {
      return <FaSpinner className="spinner submission-status" />;
    }

    return null;
  };

  const getTestBackgroundColor = (status) => {
    if (status === 'success') {
      return '#C8E6C9'; // Verde pastel
    }

    if (status === 'fail') {
      return '#FFCDD2'; // Vermelho pastel
    }

    return '#FFF'; // Fundo branco por padrão
  };

  return (
    <div className="test-cases">
      <div className="cases open-cases">
        <label>Testes abertos (0/{openTests.length})</label>
        <div className="tests">
          {openTests?.length && openTests.map((test, index) => (
            <div
              key={index}
              className="test"
              style={{
                backgroundColor: getTestBackgroundColor(status),
              }}
              onClick={() => setShowTestInformation(!showTestInformation)}
            >
              <span>Teste {index + 1}</span>
              {renderTestStatus(status)}
            </div>
          ))}
        </div>
      </div>
      <div className="cases close-cases">
        <label>Testes fechados (0/{closedTests.length})</label>
        <div className="tests">
          {closedTests?.length && closedTests.map((test, index) => (
            <div
              className="test"
              key={index}
              style={{
                backgroundColor: getTestBackgroundColor(status),
              }}
            >
              <span>Teste {index + 1}</span>
              {status === 'processing' ? (
                <FaSpinner className="spinner submission-status" />
              ) : (
                <CiLock className="submission-status" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const TestInformation = ({ showTestInformation, setShowTestInformation }) => {
  return (
    <div className="test-cases">
      <div className="test-info-header">
        <h2>Teste 1</h2>
        <IoMdClose
          onClick={() => setShowTestInformation(!showTestInformation)}
          size={25}
          className="close-test-info"
        />
      </div>
      <label>Entrada:</label>
      <textarea disabled className="custom-textarea">
        Entrada
      </textarea>

      <label>Saída esperada:</label>
      <textarea disabled className="custom-textarea">
        A B
      </textarea>

      <label>Saída obtida:</label>
      <textarea disabled className="custom-textarea">
        A B
      </textarea>
    </div>
  );
};

export { TestPanel, TestInformation };
